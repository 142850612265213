@import '../../../base/styles/base-config';

.c_language-switch {
    position: relative;

    &__type-button {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-color: $color-white;
        border: none;
        border-radius: $border-radius;
        overflow: hidden;
        padding: baseline(0.5) baseline();
        height: baseline(5);
        min-width: baseline(5);
        text-decoration: none;
        font-size: $font-base-fontsize--smaller;
        text-align: center;
        color: $color-primary;
    }
    &__type-button-label {
        line-height: 1;
        font-weight: 600;
        margin-top: baseline() / 2;
    }

    &__type-select {
        position: relative;
        display: block;
        width: 100%;
        height: baseline(4);
        border: 1px solid $color-primary;
        padding: 0 baseline();
        line-height: baseline(4);
        color: $color-primary;
    }
    &__flag {
        width: 27px;
    }
    &__select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
