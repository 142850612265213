@import '../../base/styles/base-config';

.c_cart {
    &__item {
        &:not(:last-child) {
            margin-bottom: baseline();
            @include media-breakpoint-up(md) {
                margin-bottom: baseline(2);
            }
        }
    }
    &__total-wrapper {
        padding: 0 baseline(1);
    }
    &__total {
        color: $color-white;
    }
    &__submit-wrapper {
        margin-top: baseline(2);
    }
}
