@import '../../base/styles/base-config';

.c_orders {
    &__item {
        margin-bottom: baseline();
        @include media-breakpoint-up(md) {
            margin-bottom: baseline(2);
        }
    }
}
