@import '../../base/styles/base-config';

.c_cart-detail {
    &__delete {
        margin-top: baseline(1);
    }
    &__confirm-window {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 9;
    }
}
