@import '../../base/styles/base-config';

.c_dashboard {
    &__reseller {
        margin-top: baseline();
        @include media-breakpoint-up(md) {
            margin-top: baseline(2);
        }
    }
    &__client-icon {
        color: $color-secondary;
        flex-shrink: 0;
    }

    &__card {
        overflow: visible;
    }
    &__selector {
        position: relative;
        margin-top: baseline(2);
    }
}
