@import '../../../base/styles/base-config';

.c_autocomplete-select {
    $self: &;
    position: relative;
    width: 100%;

    &__label,
    &__input {
        width: 100%;
    }
    &__label {
        display: block;
        color: $color-primary;
        margin-bottom: baseline() / 2;
        &.s_error {
            color: $color-danger;
        }
    }
    &__input-wrapper {
        position: relative;
    }

    // styling overrides for the plugin component!!
    &__container {
        position: relative;
    }
    &__select {
        &__single-value,
        &__placeholder {
            color: $color-primary !important;
        }
        &__menu {
            border-radius: 0 !important;
            box-shadow: none !important;
            border: 1px $color-primary solid !important;
            margin-top: 4px !important;
        }
        &__control {
            border-radius: 0px !important;
            border: 1px $color-primary solid !important;
            outline: none !important;
            &--menu-is-open {
                border: 1px $color-primary solid !important;
                outline: none !important;
                box-shadow: 0px 0px 4px 1px $color-primary !important;
            }
            &--is-focussed {
                border: 1px $color-primary solid !important;
                outline: none !important;
                box-shadow: 0px 0px 4px 1px $color-primary !important;
            }
        }
        &__option {
            &--is-focussed {
                background-color: $color-primary--lighter !important;
            }
            &--is-selected {
                background-color: $color-primary !important;
                color: $color-white !important;
            }
            &:hover {
                background-color: $color-primary--alt !important;
                color: $color-white !important;
            }
        }
    }
}
