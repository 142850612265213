@import '../../base/styles/base-config';

.c_loading-screen {
    width: 100%;
    height: 100%;

    &__logo {
        margin-bottom: 200px;
    }
}
