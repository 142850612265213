@import '../../../base/styles/base-config';

.c_layout-home {
    &__col {
        display: flex;
        flex-direction: column;
    }
    &__img {
        display: none;
        @include media-breakpoint-up(md) {
            display: flex;
        }
        width: 100%;
        height: 100%;
        background: {
            position: center;
            size: cover;
        }
        border-radius: 2px;
        flex: 1 1 320px;
        min-height: 320px;

        &--1 {
            margin-top: baseline();
            @include media-breakpoint-up(md) {
                margin-top: baseline(2);
            }
            background: {
                position: 50% 10%;
            }
        }
        &--2 {
            max-height: 600px;
        }
    }

    &__contact {
        margin-top: baseline();
        @include media-breakpoint-up(md) {
            margin-top: baseline(2);
        }
    }
}
