@import '../../../base/styles/base-config';

.c_title {
    font-family: $font-family-body;
    font-weight: bold;

    // sizes
    &--size-1 {
        font-size: 1.142857143rem; // 16px;
    }
    &--size-2 {
        font-size: 1rem; // 16px;
    }

    // colors
    &--color-primary {
        color: $color-primary;
        b {
            color: $color-secondary;
        }
    }
    &--color-secondary {
        color: $color-secondary;
        b {
            color: $color-primary;
        }
    }
    &--color-white {
        color: $color-white;
    }
}
