@import '../../../base/styles/base-config';

.c_wysiwyg {
    // // heading styles
    // // **************************************************
    // h1 {
    //     @extend .c_title;
    //     @extend .c_title--size-1;
    //     @extend .c_title--color-primary;
    // }
    // @for $size from 2 to 7 {
    //     h#{$size} {
    //         @extend .c_title;
    //         @extend .c_title--color-primary;
    //         @extend .c_title--size-#{$size + 1} !optional;
    //     }
    // }

    .secondary {
        color: $color-secondary;
    }

    // // spacing
    // // **************************************************
    .c_title {
        margin-bottom: baseline();
    }
    p + .c_title {
        padding-top: baseline(2);
    }
    ul,
    ol,
    p {
        margin-bottom: baseline();
    }

    // ul and ol styles
    // **************************************************
    ul {
        li {
            display: block;
            position: relative;
            padding-left: $font-list-padding;
            &:not(:last-child) {
                margin-bottom: $font-list-spacing-y;
            }

            &::before {
                content: '';
                display: inline-block;
                width: 0.25rem;
                height: 0.25rem;
                background-color: $color-secondary;
                position: absolute;
                top: 0.65rem;
                left: 0;
                border-radius: 0.25rem;
            }
        }
        ul {
            margin-top: $font-list-spacing-y;
            margin-bottom: 0 !important;
        }
    }
    ol {
        counter-reset: ol;
        li {
            display: block;
            position: relative;
            padding-left: $font-list-padding;
            &:not(:last-child) {
                margin-bottom: $font-list-spacing-y;
            }

            // make sure nested ordered lists have extra padding on the left side because of the extra numbers in nested items
            // for example: "4.11.2" needs more space then "4"
            $sel: '';
            @for $i from 1 through 4 {
                $sel: if($i==1, 'li', selector-nest($sel, 'li'));
                #{$sel} {
                    padding-left: ($font-list-padding + ($i * $font-list-padding-nested));
                }
            }

            ol {
                counter-reset: ol;
                margin-bottom: 0 !important;
            }

            &::before {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                color: $color-secondary;
                content: counters(ol, '.');
                counter-increment: ol;
            }
        }
        ol {
            margin-top: $font-list-spacing-y;
            margin-bottom: 0 !important;
        }
    }

    &--white {
        color: $color-white;
        p,
        a {
            color: $color-white;
        }
    }

    &--centered {
        text-align: center;
    }
}
