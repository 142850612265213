@import '../../../base/styles/base-config';

.c_card {
    position: relative;
    display: block;
    text-decoration: none;
    background-color: $color-white;
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: $box-shadow;

    &--grouped {
        box-shadow: none;
        border-radius: 0;
    }

    &__inner {
        padding: baseline();
        &--default,
        &--contact {
            @include media-breakpoint-up(md) {
                padding: baseline(2);
            }
        }
    }

    // PRODUCT
    &__product {
        &-number {
            flex: 0 0 baseline(6);
            min-width: baseline(6);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-icon {
            margin-left: auto;
            &--primary {
                color: $color-primary--alt;
            }
            &--secondary {
                color: $color-secondary;
            }
        }
        &-icon-icon {
            transform: translateY(0.142857143rem);
        }
    }

    // ORDER
    &__order {
        &-date {
            flex: 0 0 baseline(8);
            min-width: baseline(8);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-icon {
            margin-left: auto;
            &--primary {
                color: $color-primary--alt;
            }
            &--secondary {
                color: $color-secondary;
            }
        }
        &-icon-icon {
            transform: translateY(0.142857143rem);
        }
    }

    // CONTACT
    &--contact {
        background: $color-primary--alt;
    }
    &__contact {
        &-img {
            display: block;
            width: baseline(5);
            border-radius: baseline(8);
            overflow: hidden;

            @include media-breakpoint-up(md) {
                width: baseline(8);
            }
        }
        &-text {
            color: $color-white;
        }
        &-tel {
            color: $color-white;
            text-decoration: none;
            &:hover {
                color: $color-white;
            }
        }
        &-icon {
            transform: rotate(45deg) translateY(0.142857143rem);
        }
    }
}
