@import '../../../base/styles/base-config';

.c_order-overview {
    &__icon {
        position: absolute;
        top: baseline(1.5);
        right: baseline(1.5);
        color: $color-secondary;
    }
    &__item {
        margin-bottom: baseline();
    }
    &__products {
        &--success {
            margin-left: -#{baseline(1)};
            margin-right: -#{baseline(1)};
        }
    }
}
