@import '../../../base/styles/base-config';

.c_preloader {
    display: inline-block;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: all 200ms ease;
    &.s_active {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    &--with-overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &--primary {
            background-color: transparentize($color-primary, 0.4);
        }
        &--secondary {
            background-color: transparentize($color-secondary, 0.4);
        }
        &--white {
            background-color: rgba(255, 255, 255, 0.8);
        }
    }

    // width/height!
    &__container {
        width: baseline(2);
        height: baseline(2);
        transform: rotate(45deg);
    }

    // Spinner
    &__spinner {
        width: 100%;
        height: 100%;
        position: relative;
        &--white {
            background: $color-white;
        }
        &--primary {
            background: $color-primary;
        }
        &--secondary {
            background: $color-secondary;
        }

        animation: sk-rotateplane 1.2s infinite ease-in-out;
    }
}
@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
