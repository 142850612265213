html {
    display: flex;
}
body,
#root {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
    background-color: $color-primary;
}
