@import '../../../base/styles/base-config';

.c_card-group {
    position: relative;
    background-color: $color-white;
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: $box-shadow;

    &__spacer {
        display: block;
        width: 100%;
        height: 1px;
        background: $color-grey;
    }
}
