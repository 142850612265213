/* =============================================================================
    Meyer reset sheet
    ========================================================================== */
/* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* =============================================================================
    Base
    ========================================================================== */
* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-touch-callout: none;
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    outline: none;
}

/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 * 2. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
 * 3. facebook bug not showing canvas in ie7/ie8 positon:relative weghalen
 */
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
}

html,
body {
    background: $color-primary;
}

/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */
audio,
canvas,
img,
video {
    vertical-align: middle;
}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 */
::-moz-selection {
    text-shadow: none;
}
::selection {
    text-shadow: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
    display: none;
}

/*
 * The default box-model is set to “content-box” which means it does not include padding and borders into the width value.
 * The default box-sizing would make the width be 100% plus the padding but using border-box would include the padding in the width.
 */
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/**
 * Use a more readable tab size (opinionated).
 */
:root {
    -moz-tab-size: 4;
    tab-size: 4;
}

/* =============================================================================
    HTML5 display definitions
    ========================================================================== */
main {
    display: block;
}
canvas,
video {
    display: inline-block;
}

audio {
    :not([controls]) {
        display: none;
        height: 0;
    }
}

/* =============================================================================
    Links
    ========================================================================== */
a {
    cursor: pointer;

    /* Improve readability when focused and hovered in all browsers: h5bp.com/h */
    &:hover,
    &:active {
        outline: 0;
    }
}

/* =============================================================================
    Forms
    ========================================================================== */
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
    overflow: auto;
    resize: none;
}

/**
 * disable arrows on field type number.
 */
form input[type='number']::-webkit-outer-spin-button,
form input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
    vertical-align: baseline;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
    /* 1 */
    overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
    /* 1 */
    text-transform: none;
}
/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button[disabled],
input[disabled] {
    cursor: default;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

/**
 * Make sure a unstyled radio&checkbox are visible
 * these default styles are reset by *{-webkit-appearance:none;} This causes the checkbox/radiobutton to be invisible..
 */
[type='checkbox'] {
    -webkit-appearance: checkbox;
}
[type='radio'] {
    -webkit-appearance: radio;
}

/**
 * remove IE11 native select arrow
 */
select::-ms-expand {
    display: none;
}
/**
 * remove IE11 native clear cross for inputs
 */
input::-ms-clear {
    display: none;
}

/* =============================================================================
    Tables
    ========================================================================== */
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td {
    vertical-align: top;
}
