@import '../../../base/styles/base-config';

.c_input {
    $self: &;
    position: relative;
    width: 100%;

    &__label,
    &__input {
        width: 100%;
    }
    &__label {
        display: block;
        color: $color-primary;
        margin-bottom: baseline() / 2;
        &.s_error {
            color: $color-danger;
        }
    }
    &__input-wrapper {
        position: relative;
    }
    &__input {
        height: baseline(4);
        border: 1px solid $color-primary;
        padding: 0 baseline();
        line-height: baseline(4);
        color: $color-primary;

        &--textarea {
            display: block;
            height: baseline(12);
            line-height: $font-base-lineheight;
            padding-top: baseline(1) / 2;
            padding-bottom: baseline(1) / 2;
        }

        &:disabled,
        &.s_disabled {
            opacity: 0.4;
            cursor: not-allowed;
            pointer-events: none;
        }

        &.s_error {
            color: $color-danger;
            border-color: $color-danger;
        }
    }
    &__spinners {
        position: absolute;
        top: 0;
        right: 0;
    }
    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: baseline(4);
        height: baseline(4);
        border: none;
        background: $color-primary;
        outline: none;
        padding: 0;
        color: $color-white;
        cursor: pointer;

        &--remove {
            background: $color-primary--light;
        }
    }

    &--inverted {
        #{$self}__label {
            color: $color-white;
            &.s_error {
                color: $color-white;
            }
        }
        #{$self}__input {
            background: transparent;
            color: $color-white;
            border-color: $color-white;
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
