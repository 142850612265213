/*  ==========================================================================
    Helperclasses
    ========================================================================== */

/* Disable selection of text */
.h_noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Disable dragging of elements */
.h_nodrag {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/* Disable wrapping of text */
.h_nowrap {
    white-space: nowrap;
}

/* stretches a link to the next relative positioned parent */
.h_stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color: rgba(0, 0, 0, 0);
}
