@import '../../../base/styles/base-config';

.c_footer {
    &__inner {
        height: baseline();
        @include media-breakpoint-up(md) {
            height: baseline(2);
        }
    }
}
