@import '../../../base/styles/base-config';

.c_select {
    $self: &;
    position: relative;
    width: 100%;

    &__label,
    &__input {
        width: 100%;
    }
    &__label {
        display: block;
        color: $color-primary;
        margin-bottom: baseline() / 2;
        &.s_error {
            color: $color-danger;
        }
    }
    &__input-wrapper {
        position: relative;
    }
    &__input {
        height: baseline(4);
        border: 1px solid $color-primary;
        padding: 0 baseline();
        padding-right: baseline(4);
        background: $color-white;
        line-height: baseline(4);
        color: $color-primary;

        &:disabled,
        &.s_disabled {
            opacity: 0.4;
            cursor: not-allowed;
            pointer-events: none;
        }

        &.s_error {
            color: $color-danger;
            border-color: $color-danger;
        }
    }
    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: baseline(4);
        pointer-events: none;
    }

    &--inverted {
        #{$self}__label {
            color: $color-white;
            &.s_error {
                color: $color-white;
            }
        }
        #{$self}__input {
            background: transparent;
            color: $color-white;
            border-color: $color-white;
        }
    }
}
