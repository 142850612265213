@import '../../base/styles/base-config';

.c_offline-screen {
    width: 100%;
    height: 100%;

    &__logo {
        margin-bottom: 88px;
    }
}
