@import '../../../base/styles/base-config';

.c_alert-text {
    position: relative;
    &__label {
        font-style: italic;
    }

    &--danger {
        color: $color-danger;
    }
    &--warning {
        color: $color-warning;
    }
    &--success {
        color: $color-success;
    }
    &--info {
        color: $color-info;
    }
    &--white {
        color: $color-white;
    }
    &--default {
        color: $color-body;
    }
    &--primary {
        color: $color-primary;
    }
}
