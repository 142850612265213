@import '../../../base/styles/base-config';

.c_layout-default {
    &__col-img {
        display: none;
        @include media-breakpoint-up(md) {
            display: flex;
            min-height: 600px;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        max-height: 600px;
        background: {
            position: center;
            size: cover;
        }
        border-radius: 2px;
    }
    &__content {
        min-height: baseline(5);
    }
}
