// import config and functions e.d.
@import 'base/styles/base-config.scss';

// import base styles
@import 'base/styles/base-styles.scss';

.Toastify__toast {
    border-radius: $border-radius;
    padding: baseline();
}
