@import '../../../base/styles/base-config';

.c_header {
    $self: &;
    padding: baseline() 0;
    @include media-breakpoint-up(md) {
        padding: baseline(3) 0;
    }
    &__right {
        margin-bottom: baseline(2);
    }
    &__buttons,
    &__actions {
        display: flex;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        overflow: hidden;
    }
    &__button {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-color: $color-white;
        padding: baseline(0.5) baseline();
        height: baseline(5);
        min-width: baseline(5);
        @include media-breakpoint-up(md) {
            padding: baseline(0.5) baseline(1.5);
            min-width: baseline(6);
        }
        text-decoration: none;
        font-size: $font-base-fontsize--smaller;

        &-label {
            line-height: 1;
            font-weight: 600;
            margin-top: baseline() / 2;
        }

        &--add {
            border-top-left-radius: baseline(2.5);
            border-bottom-left-radius: baseline(2.5);
            width: baseline(5);
            justify-content: center;
            background: $color-primary--lighter;
        }
        &--cart {
            min-width: baseline(6);
            @include media-breakpoint-down(md) {
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -#{baseline(1)};
                    width: baseline(1);
                    height: 100%;
                    background: $color-white;
                }
                &.active {
                    &::before {
                        background: $color-primary--alt;
                    }
                }
            }
        }

        &.active {
            background-color: $color-primary--alt;
            color: $color-white;

            #{$self}__button-icon {
                color: $color-white;
            }
        }
    }
    &__cart-counter {
        position: absolute;
        top: 0;
        right: baseline() / 2;
        display: block;
        background: $color-secondary;
        color: $color-white;
        width: 19px;
        height: 19px;
        border-radius: 19px;
        overflow: hidden;
        line-height: 19px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
    }

    // make the actions floating on smaller devices
    &__actions {
        border-top-left-radius: baseline(2.5);
        border-bottom-left-radius: baseline(2.5);
        margin-right: baseline(2);
        @include media-breakpoint-down(sm) {
            position: fixed;
            bottom: baseline(8);
            right: 0;
            z-index: 9;
            margin-right: 0;
            padding-right: baseline(1);
        }
    }
}
