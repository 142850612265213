/*  =============================================================================
    html/body
    ========================================================================== */
html {
    font-size: $font-body-fontsize;
    font-family: $font-family-body;
    font-weight: $font-body-weight;
    color: $color-body;
}
body {
    line-height: $font-base-lineheight;
}

/*  =============================================================================
    Basic font reset stuff
    ========================================================================== */
abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

i,
em {
    font-style: italic;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-grey;
    padding: 0;
    width: 100%;
    margin: baseline() 0;
}

/* Redeclare monospace font family: h5bp.com/j */
pre,
code,
kbd,
samp {
    font-family: monospace, serif;
}

/* Improve readability of pre-formatted text in all browsers */
pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

q {
    quotes: none;
}
q:before,
q:after {
    content: '';
    content: none;
}

/* Position subscript and superscript content without affecting line-height: h5bp.com/k */
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
small {
    font-size: $font-base-fontsize--smaller;
    vertical-align: baseline;
}

/*  =============================================================================
    Basic p and a styles
    ========================================================================== */
a {
    color: $color-link;
    &:hover {
        color: $color-link--alt;
    }
}

/*  =============================================================================
    Text utils
    ========================================================================== */
.text-smaller {
    font-size: $font-base-fontsize--smaller;
}
.text-larger {
    font-size: $font-base-fontsize--larger;
}

/*  ==========================================================================
    Text selection
    ========================================================================== */
::-moz-selection {
    background: $color-secondary;
    color: $color-white;
}
::selection {
    background: $color-secondary;
    color: $color-white;
}
