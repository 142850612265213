@import './base/styles/base-config';

.c_app {
    &__header {
        position: relative;
    }
    &__main {
        position: relative;
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        overflow: hidden;
    }
    &__footer {
        position: relative;
    }
}

.c_app-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1000;

    &__container {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
    }
    &__wrapper {
        width: 100%;
        height: 100%;
        overflow: auto;
        pointer-events: auto;
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            // since overflown items are hidden with following rule: mimick the center behaviour by adding pseudo-elements with flex: 1;
            // justify-content: center;
            &::before,
            &::after {
                content: '';
                flex: 1;
            }
        }
    }
    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(12, 77, 139, 0.8);
        transition: opacity 0.4s ease;
        opacity: 0;
        &--in {
            opacity: 1;
        }
    }
    &__modal {
        background-color: $color-white;
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        min-height: 200px;
        opacity: 0;
        transition: opacity 0.3s ease 0.05s, transform 0.4s ease 0s;
        transform: scale(0.5);
        @include media-breakpoint-up(md) {
            opacity: 0;
            transform: scale(1) translateY(20px);
            height: auto;
            max-width: 552px;
        }
        &--in {
            opacity: 1;
            transform: scale(1) translateY(0);
            transition: opacity 0.1s ease 0s, transform 0.4s ease 0s;
        }
    }
}
.modal-open {
    overflow: hidden !important;
}

.page-fade {
    &-enter {
        opacity: 0;
    }
    &-enter-active {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 1;
        transition: opacity 0.4s ease, transform 0.4s ease;
    }
    &-exit {
        opacity: 1;
    }
    &-exit-active {
        opacity: 0;
        transition: opacity 0.4s ease, transform 0.4s ease;
    }
}
