@import '../../../base/styles/base-config';

.c_order-form {
    &__input {
        margin-bottom: baseline();
    }
    &__submit-wrapper {
        margin-top: baseline(2);
    }

    &__silo-spacer {
        margin: baseline(2) 0;
    }
    &__silo-actions {
        margin-top: -#{baseline()};
        margin-bottom: baseline(2);
    }
}
