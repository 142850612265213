@import '../../../base/styles/base-config';

.c_layout-modal {
    position: relative;
    background-color: $color-white;
    @include media-breakpoint-down(sm) {
        border-top: baseline() solid $color-primary;
    }

    &__inner {
        position: relative;
        padding: baseline(2);
    }
    &__close {
        position: absolute;
        top: calc(#{baseline(2)} + 2px);
        right: baseline(2);
        width: baseline(4);
        height: baseline(4);
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        color: $color-secondary;
        cursor: pointer;
        text-align: right;

        &-icon {
            pointer-events: none;
        }
    }
}
