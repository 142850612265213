@import '../../../base/styles/base-config';

.c_radiobutton {
    $self: &;
    overflow: hidden;
    color: $color-primary;

    &__label {
        position: relative;
        cursor: pointer;
        &.s_error {
            color: $color-danger;
        }
    }
    &__input {
        position: absolute;
        left: -1000px;
    }
    &__checkbox {
        width: baseline(2.5);
        height: baseline(2.5);
        flex: 0 0 baseline(2.5);
        border-radius: 100%;
        border: 1px solid $color-primary;
        color: $color-primary;

        &.s_error {
            border-color: $color-danger;
        }
    }
    &__icon {
        display: block;
        width: 11px;
        height: 11px;
        background: $color-primary;
        border-radius: 100%;
        &.s_error {
            background: $color-danger;
        }
    }

    &--inverted {
        color: $color-white;
        #{$self}__checkbox {
            border-color: $color-white;
            color: $color-white;
        }
        #{$self}__icon {
            background-color: $color-white;
        }
    }
}
