@import '../../../base/styles/base-config';

.c_button {
    display: inline-flex;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    border-radius: baseline(4);
    padding: 0 baseline(2.5);
    text-decoration: none;
    cursor: pointer;
    line-height: baseline(4);
    border: none;
    font-size: $font-base-fontsize--larger;
    font-weight: 600;
    -webkit-appearance: none;

    &__icon {
        margin-right: baseline(0.5);
    }

    &:not(&--inline) {
        box-shadow: $box-shadow;
    }

    &--primary {
        background: $color-primary;
        color: $color-white;
        &:hover {
            color: $color-white;
        }
    }
    &--secondary {
        background: $color-secondary;
        color: $color-white;
        &:hover {
            color: $color-white;
        }
    }
    &--white {
        background: $color-white;
        color: $color-primary;
        &:hover {
            color: $color-primary;
        }
    }

    // inline style
    &--inline {
        text-align: left;
        padding: 0;
        border-radius: 0;
        background: transparent;
        font-weight: normal;
        text-decoration: underline;
        line-height: $font-base-lineheight;
    }
    &--inline#{&}--primary {
        color: $color-primary;
    }
    &--inline#{&}--secondary {
        color: $color-secondary;
    }
    &--inline#{&}--white {
        color: $color-white;
    }

    &--size-small {
        font-size: $font-base-fontsize;
    }

    // state modifiers
    &:disabled,
    &.s_disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }
}
