/* =============================================================================
    Colors
   ========================================================================== */
$color-white: #fff;
$color-black: #000;

$color-grey: #e4e4e4;

// $color-grey--light: lighten($color-grey, 20%);
// $color-grey--lighter: lighten($color-grey, 20%);
// $color-grey--dark: darken($color-grey, 10%);
// $color-grey--darker: darken($color-grey, 20%);

$color-primary: #0c4d8b;
$color-primary--alt: #497aa9;
$color-primary--light: #497aa9;
$color-primary--lighter: #eaf0fc;
// $color-primary--lightest: #f2f8ff; // background - light

$color-secondary: #e1113b;
$color-secondary--alt: #f08da1;
// $color-secondary--dark: darken($color-secondary, 10%);
// $color-secondary--darker: darken($color-secondary, 20%);
// $color-secondary--light: lighten($color-secondary, 10%);
// $color-secondary--lighter: lighten($color-secondary, 20%);

// $color-tertiary: #7990b4;
// $color-tertiary--dark: darken($color-tertiary, 10%);
// $color-tertiary--light: lighten($color-tertiary, 10%);

/** font colors **/
$color-body: $color-primary;
$color-link: $color-primary;
$color-link--alt: $color-primary--alt;
// $color-muted-text: $color-grey;

/** feedback colors (for form-feedback e.d.) **/
$color-success: #2dcb48;
// $color-success--alt: #28b440;
$color-warning: #ffcc00;
// $color-warning--alt: #e4b80b;
$color-danger: #f43319;
// $color-danger--alt: #d12d17;
$color-info: #77b2f1;
// $color-info--alt: #5e9cdd;

/** background colors  **/
$color-background--dark: $color-primary;
$color-background--light: $color-primary--lighter;

/* =============================================================================
    Font
    ========================================================================== */
$font-family-body: 'Open Sans', sans-serif;
$font-body-fontsize: 14px;
$font-body-weight: 400;
$font-base-fontsize: 1rem;
$font-base-fontsize--smaller: 0.714285714rem; // 10px
$font-base-fontsize--larger: 1.142857143rem; // 16px
$font-base-lineheight: 1.4;

// list paddings for ul/ol
$font-list-padding: 1rem;
$font-list-padding-nested: 1rem;
$font-list-spacing-y: 0.5rem; // the vertical spacing between li and nested li

/* =============================================================================
    Animation
    ========================================================================== */
$animation-default-speed: 0.2s;

/* =============================================================================
    Bootstrap grid config
    (required for bootstrap imports from npm package)
   ========================================================================== */
$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);
$container-max-widths: (
    sm: 1024px,
);

/* =============================================================================
    Spacing
   ========================================================================== */
$spacer: 1rem;
$spacers: ();

// 4px, 8px, 16px, 24px, 32px, 48px, 56px, 64px
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 2,
        ),
        6: (
            $spacer * 3,
        ),
        7: (
            $spacer * 3.5,
        ),
        8: (
            $spacer * 4,
        ),
    ),
    $spacers
);

/* =============================================================================
    Spacing & baseline
   ========================================================================== */
$baseline: 10px;

/* =============================================================================
    Elevation
   ========================================================================== */
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

/* =============================================================================
    Other stuff
   ========================================================================== */
$border-radius: 2px;
