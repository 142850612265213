@import '../../base/styles/base-config';

.c_profile {
    &__logout {
        background: transparent;
        border: none;
        padding: 0;
        outline: none;
        color: $color-primary--light;
        text-decoration: underline;
        font-size: $font-base-fontsize--smaller;
        position: absolute;
        top: baseline(2);
        right: baseline();
        cursor: pointer;

        @include media-breakpoint-up(md) {
            right: baseline(2);
        }
    }
    &__title,
    &__reseller {
        margin-bottom: baseline(2);
    }
    &__link {
        text-decoration: none;
    }
    &__infotext {
        color: $color-primary--light;
    }
    &__item {
        margin-bottom: baseline(2);
    }
    &__lang {
        p {
            margin-bottom: 0;
        }
    }
}
